// MIXINS___________________________________________________________________
$phone: 600px;
$tablet: 768px;
$desktop: 1024px;

@mixin phone{
  @media (max-width: 599px) {
    @content;
  }
}

@mixin tablet{
  @media (min-width: 600px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop{
  @media (min-width: 1025px) {
    @content;
  }
}


// WHOLE APP_________________________________________________________________
html {
  background: url(../assets/background-nebula.svg) #8078db;
  background-position: center center;
  background-size: 115%;

  body{
    background-color: transparent;


    .MuiMenu-paper{
      background-color: #8078db;

      .MuiMenuItem-root{
        padding: 0 16px;
        background-color: rgb(128, 120, 219);
        border-color: #1b193f;
    
        a{
          text-decoration: none;
          color: #cfcfcf;
    
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
}


// HEADER PAGE_________________________________________________________________
header{
  position: absolute !important;
  top: 0px;
  background-color: transparent !important;
  z-index: 100;
  box-shadow: none !important;

  .logo{
    width: 250px;
  }

  .navbar-brand{
    color: #cfcfcf;
  }

  .navbar-nav a, .navbar-nav button{
    color: #cfcfcf;
    margin: 0 10px;
  }

  .navbar-nav button{
    padding-bottom: 0;
    background-color: rgb(128, 120, 219);
    border-color: #1b193f;

    &:hover{
      background-color: #3b4647;
      border-color: #1b193f;
    }

    a{
      text-decoration: none;
      color: #cfcfcf;

      &:hover{
        text-decoration: none;
      }
    }
  }
}


// HOME PAGE___________________________________________________________________
.home-wrapper {
  background: url(../assets/background-moon.svg) no-repeat rgb(128, 120, 219);
  background-size: 101%;
  padding: 0;

  .row{
    margin: 0;
  }


  .rocket {
    position: absolute;
    left: 50vw;
    top: -300px;
    bottom: 0;
    width: 10vw;
    height: auto;

    @include phone{
      top: -400px;
    }

    @include tablet{
      top: -350px;
    }

  }
  

  h1.cta{
    font-size: 2.5vw;
    margin: 40% 0 0 0;

    @include phone{
      margin-top: 25%;
      font-size: 5vw;
      margin-left: 30px;

    }

    @include tablet{
      margin-top: 25%;
      font-size: 3.5vw;
      margin-left: 60px;
    }
  }

  h5.cta-sub{
    font-size: 2vw;
    font-weight: 200;
    max-width: 600px;

    @include phone{
      font-size: 4vw;
    }

    @include tablet{
      font-size: 2.5vw;
    }
  }

  h1,h5{
    color: #fff;
    text-align: left;

    @include phone{
      margin-left: 30px;
      margin-right: 30px;
    }

    @include tablet{
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  .future{
    margin: 50% 0 0 0;

    .tardis-wrapper{
      position: absolute;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto;
      z-index: 1;
      margin-top: -35%;
      width: 30%;
      transition-duration: 5s;
      height: auto;
  
      .tardis{
        width: 100%;
        height: auto;
      }
    }

    h1.agd{
      position: relative;
      z-index: 2;
      font-size: 2vw;
      color: #fff;
      text-align: center;
  
      @include phone{
        font-size: 5vw;
      }
  
      @include tablet{
        font-size: 3.5vw;
      }
    }
  
    h5.agd-sub{
      position: relative;
      z-index: 2;
      font-size: 1.5vw;
      font-weight: 300;
      color: #fff;
      text-align: center;
      margin-bottom: 20px;
  
  
      @include phone{
        font-size: 3vw;
      }
  
      @include tablet{
        font-size: 2vw;
      }
    }

    .list{
      font-size: 2vw;
      color: #fff;
      list-style: none;
      text-align: center;

      li{
        float: left;
        width: 50%;

        &:before{
          color:limegreen;
          font-size: 1.5vw;
          content: '✓';

          @include phone{
            font-size: 2.5vw;
          }
      
          @include tablet{
            font-size: 2vw;
          }
        }

        @include phone{
          width: 42%;
        }
      }

      @include phone{
        font-size: 4vw;
      }
  
      @include tablet{
        font-size: 3vw;
      }
    }
  }

  .cards{
    margin-top: 70%;
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;

    @include phone{
      margin-top: 30%;
    }

    @include tablet{
      margin-top: 50%;
    }

    .card{
      background-color: #6d6ac6;
      margin: 20px;
      width: 25%;
      height: auto;
      transition: 0.3s;

      div, p{
        background-color: transparent;
        color: #efefef;
      }

      @include phone{
        width: 65%;
      }
  
      @include tablet{
        width: 40%;
      }
    }
  }

  .copyright-wrapper{
    width: 100%;
    overflow:hidden;

    .trees{
      width: 104%;
      height: auto;
      margin-left:-15px;
    }

    p{
      margin-top: -33px;
      padding-bottom: 12px;
      text-align: center;
      width: 100%;
    }
    p, a{
      color: #cfcfcf;

      &:active, &:hover{
        color: #cfcfcf;
      }
    }
  }
}


// CONTACT PAGE___________________________________________________________________
.contact-wrapper {

  .spaceship{
    width: 100%;
    height: auto;
    margin-bottom: -272px;
  }

  h1{
    color: #fff;
  }

  .copyright{
    p, a{
      color: #cfcfcf;

      &:active, &:hover{
        color: #cfcfcf;
      }
    }
  }

  form{
    margin-top: 56px;

    button{
      background-color: #7e01b0;
      border: none !important;

      &:hover{
        background-color: #610085;
      }

      &:active{
        background-color: #610085;
        border: none !important;
      }
    }

    .MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled{
      color: #fff !important;
      background-color: rgb(128, 120, 219);
      border-radius: 25px;
      padding: 0 8px;
      margin: 1px 0 0 -4px;
    }
    
    .MuiInputBase-root{
      background-color: #cfcfcf;
  
      .Mui-focused{
        color: rgb(128, 120, 219) !important;
      }
  
      fieldset{
        border-color: rgb(128, 120, 219) !important;
        color: rgb(128, 120, 219) !important;
      }
    }
  }

}


// ABOUT PAGE___________________________________________________________________
.about-wrapper {

  h1{
    position: relative;
    z-index: 2;
    margin: 60px 0 0 0;
    font-size: 2vw;
    color: #fff;
    text-align: center;

    @include phone{
      font-size: 5vw;
    }

    @include tablet{
      font-size: 3.5vw;
    }
  }

  h5{
    position: relative;
    z-index: 2;
    font-size: 1.5vw;
    font-weight: 300;
    color: #fff;
    text-align: center;
    margin:20px 0 40px 0;


    @include phone{
      font-size: 3vw;
    }

    @include tablet{
      font-size: 2vw;
    }
  }

  .cards{
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
    margin-bottom: 50px;

    @include phone{
      margin-top: 10%;
    }

    @include tablet{
      margin-top: 20%;
    }

    .card{
      background-color: #6d6ac6;
      margin: 20px;
      height: auto;
      transition: 0.3s;

      div, p{
        background-color: transparent;
        color: #efefef;
      }

     
    }
  }

  .copyright{
    p, a{
      color: #cfcfcf;

      &:active, &:hover{
        color: #cfcfcf;
      }
    }
  }

}
